const IS_PROD = true;
const IS_LOCAL_SERVER = false;

let baseUrl = '';
let siteBaseUrl = '';
let qrUrl = '';

if (IS_PROD && !IS_LOCAL_SERVER) {
  baseUrl = 'https://api.rxhometest.com';
  siteBaseUrl = 'https://rxhometest.com';
  qrUrl = 'https://qr.rxhometest.com';
} else if (!IS_PROD && IS_LOCAL_SERVER) {
  baseUrl = 'http://localhost:4000';
  siteBaseUrl = 'http://localhost:3000';
  qrUrl = 'http://localhost:8081';
} else if (!IS_PROD && !IS_LOCAL_SERVER) {
  baseUrl = 'https://api-test.rxhometest.com';
  siteBaseUrl = 'https://test.rxhometest.com';
  qrUrl = 'https://qr-test.rxhometest.com';
}

module.exports = {
  baseUrl,
  fileBaseUrl: 'https://rxhometest.sfo3.digitaloceanspaces.com/web',
  siteBaseUrl,
  qrUrl,
};
